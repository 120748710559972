import { graphql } from "gatsby"
import React from "react"
import { Body } from "../components/Body"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
export default ({ data }) => {
  const {
    title,
    body: { json },
  } = data.page

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Body data={json} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      body {
        json
      }
    }
  }
`
